










import {
  defineComponent,
  useRoute,
  ssrRef,
  useContext,
  ref,
  useFetch,
} from '@nuxtjs/composition-api';
import { useContent } from '@gemini-vsf/composables';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { CacheTagPrefix, useCache } from '@gemini/vsf-cache';
import { useUrlResolver } from '~/composables/useUrlResolver';
import cacheControl from '~/helpers/cacheControl';
import { useI18n } from '~/helpers/hooks/usei18n';
import error from '~/layouts/error.vue';

interface RootComponent {
  name: string;
  props?: object;
}

function isRedirect(redirectCode: number | undefined): boolean {
  const redirectCodes = new Set().add(301).add(302);
  return redirectCodes.has(redirectCode);
}

export default defineComponent({
  name: 'RoutePage',
  components: {
    ProductPage: () => import('~/pages/Product.vue'),
    CategoryPage: () => import('~/pages/Category.vue'),
    CmsPage: () => import('~/pages/Page.vue'),
    Error: error,
  },
  middleware: cacheControl({
    'max-age': 60,
    'stale-when-revalidate': 5,
  }),
  transition: 'fade',
  setup() {
    const CacheTagPrefixUrl = 'U'; // TODO move to cache lib
    const { error: nuxtError, redirect, app, res } = useContext();
    const route = useRoute();
    const loadingPage = ref(false);
    const {
      params: { path },
      path: routePath,
    } = route.value;
    const { page: contentPage, loadContent } = useContent(`cmsPage-${path}`);
    const {
      result,
      search: resolveUrl,
      loading: urlResolverLoading,
    } = useUrlResolver();
    const rootComponent = ssrRef<RootComponent | null>(null);
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { addTags } = useCache();
    const { locale } = useI18n();
    const cmsIdentifier = `${locale}/${path}`;

    useFetch(async () => {
      loadingPage.value = true;
      rootComponent.value = { name: '' };
      await resolveUrl();

      const component: RootComponent = { name: '' };
      const urlRewrite = result?.value;
      const shouldRedirect = isRedirect(urlRewrite?.redirectCode);

      if (
        shouldRedirect &&
        urlRewrite?.urlPath &&
        urlRewrite?.urlPath !== path
      ) {
        const regx = /^(?:[a-z]+:)?\/\//i;
        const redirectPath = regx.test(urlRewrite.urlPath)
          ? urlRewrite.urlPath
          : app.localePath(`/${urlRewrite.urlPath.replace(/^(\/)/, '')}`);
        redirect(urlRewrite.redirectCode, redirectPath);
        loadingPage.value = false;
        return;
      }
      switch (urlRewrite?.entityCode) {
        case 'Product': {
          component.name = 'ProductPage';
          component.props = {
            productId: urlRewrite?.entityId,
          };
          break;
        }
        case 'ProductList': {
          component.name = 'CategoryPage';
          component.props = {
            categoryId: urlRewrite?.entityId,
          };
          break;
        }
        default: {
          // TODO define a specific case once implemented DXP cms service
          await loadContent({
            identifier: cmsIdentifier,
          });
          const cmsPageContent = contentPage?.value?.content;
          if (!cmsPageContent) {
            break;
          }
          component.name = 'CmsPage';
          component.props = {
            identifier: path,
            content: contentPage.value,
          };
          break;
        }
      }

      if (!component?.name) {
        // avoid hydration errors
        // https://github.com/nuxt/nuxt/issues/7285
        if (process.server) {
          res.statusCode = 404;
          component.name = 'error';
        } else {
          nuxtError({ statusCode: 404 });
        }
      } else {
        // avoid caching cms, the cache doesn't work with nuxtError
        // eslint-disable-next-line no-lonely-if
        if (component.name !== 'CmsPage') {
          addTags([
            { prefix: CacheTagPrefix.View, value: 'urlrewrite' },
            { prefix: CacheTagPrefixUrl, value: urlRewrite?.entityId },
          ]);
        }
      }
      rootComponent.value = component;
    });

    return {
      rootComponent,
      loading: urlResolverLoading,
      contentPage,
      loadingPage,
      result,
    };
  },
  head() {
    return {
      title: 'BestDigit',
      meta: [{ hid: 'robots', name: 'robots', content: 'index, follow' }],
    };
  },
});
